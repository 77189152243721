"use client";

import { useTranslations } from "next-intl";
import React from "react";
import {
  FallbackProps,
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryPropsWithComponent as ReactErrorBoundaryProps,
} from "react-error-boundary";

import { Alert } from "@/common/components/alert";
import Button from "@/common/components/button";
import { config } from "@/config";

export const DefaultFallbackRender: React.FC<FallbackProps> = (props) => {
  const t = useTranslations();

  if (!config.NEXT_PUBLIC_ENABLE_ERROR_BOUNDARY) {
    throw props.error;
  }

  return (
    <div className="my-8 flex justify-center">
      <Alert
        message={
          <div>
            <div className="mb-6">
              <div className="text-lg">{t("common.error-boundary-error-message")}:</div>
              <div>{props?.error?.message}</div>
              <pre className="bg-grey-10 border-grey-40 mt-2 rounded border p-3 text-xs font-thin text-black">
                {props?.error?.stack}
              </pre>
            </div>

            <Button
              variant="slim"
              onClick={() => {
                props.resetErrorBoundary();
              }}
            >
              {t("common.error-boundary-try-again")}
            </Button>
          </div>
        }
      />
    </div>
  );
};

type ErrorBoundaryProps = Omit<ReactErrorBoundaryProps, "FallbackComponent">;

export function ErrorBoundary(props: ErrorBoundaryProps) {
  return <ReactErrorBoundary FallbackComponent={DefaultFallbackRender} {...props} />;
}
