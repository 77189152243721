"use client";

import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";

import { DefaultFallbackRender } from "@/common/layout/error-boundary";

export default function Error({ error, reset }: { error: Error; reset: () => void }) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <div>
      <DefaultFallbackRender error={error} resetErrorBoundary={reset} />
    </div>
  );
}
