import { ReactNode } from "react";

interface AlertProps {
  message?: string | ReactNode;
}

export function Alert({ message }: AlertProps) {
  return (
    <div className="border-danger text-danger flex h-full items-center justify-center rounded border px-5 py-4 text-sm font-semibold">
      {message}
    </div>
  );
}
