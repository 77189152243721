import cn from "clsx";
import React, { ButtonHTMLAttributes, forwardRef } from "react";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  variant?: "flat" | "slim" | "smoke" | "primary" | "secondary" | "success";
  active?: boolean;
  type?: "submit" | "reset" | "button";
  loading?: boolean;
  disabled?: boolean;
  disableBorderRadius?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    className,
    variant = "flat",
    children,
    active,
    loading = false,
    disabled = false,
    disableBorderRadius = false,
    ...rest
  } = props;

  const rootClassName = cn(
    "inline-flex cursor-pointer items-center justify-center border-0 text-center text-[13px] font-semibold leading-4 transition duration-300 ease-in-out placeholder:text-white focus:outline-none focus-visible:outline-none md:text-sm",
    {
      "rounded-md": !disableBorderRadius,
      "bg-grey text-white px-5 md:px-6 lg:px-8 py-4 md:py-3.5 lg:py-4 hover:text-white hover:bg-grey-100 hover:shadow-cart":
        variant === "flat",
      "h-11 md:h-12 px-5 bg-grey text-white py-2 transform-none normal-case hover:text-white hover:bg-grey-100 hover:shadow-cart":
        variant === "slim",
      "h-11 md:h-12 px-5 bg-grey-10 py-2 transform-none normal-case hover:bg-grey-30":
        variant === "smoke",
      "!rounded-[32px] bg-grey text-white text-base !font-normal hover:bg-grey-100":
        variant === "primary",
      "!rounded-[32px] bg-grey text-base !border-2 border-solid border-grey-90 bg-white hover:bg-grey-100 hover:border-grey-100 hover:text-white":
        variant === "secondary",
      "!rounded-[32px] bg-success text-base text-white !font-normal !border-0 hover:bg-success-dark":
        variant === "success",
      "cursor-not-allowed": loading,
      "cursor-not-allowed hover:cursor-not-allowed": disabled,
    },
    className,
  );

  return (
    <button
      aria-pressed={active}
      data-variant={variant}
      ref={ref}
      className={rootClassName}
      disabled={disabled}
      {...rest}
    >
      {children}
      {loading && (
        <svg
          className="-mr-1 ml-3 size-5 animate-spin text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}
    </button>
  );
});

Button.displayName = "Button";

export default Button;
